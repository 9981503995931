<template>
  <q-page padding>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Validação de Cliques</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Validação de cliques" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />
    <q-form @submit.prevent="onFilterCliks">
      <q-card flat bordered>
        <q-card-section class="row q-col-gutter-md">
          <div class="col-6">
            <select-request
              :v-model="params.campaignId"
              outlined
              endpoint="/redirect?app=ADVERTISER&path=/api/get/campaign"
              option-label="name"
              option-value="id"
              :label="$t('campaign')"
              clearable
              @update="(value) => (params.campaignId = value === null ? null : value.id)"
              :rules="[(val) => !!val || 'O campo de modo de exibição é obrigatório.']"
            />
          </div>
          <div class="col-6">
            <div class="col-6">
              <date-input
                outlined
                label="Data de ativação"
                :v-model="params.date"
                @update="(value) => (params.date = value)"
                clearable
                range
                :rules="[(val) => !!val || 'O campo data de ativação é obrigatório.']"
              />
            </div>
          </div>
        </q-card-section>
        <q-card-actions vertical align="center">
          <q-btn
            unelevated
            class="full-width"
            label="Buscar"
            color="positive"
            no-caps
            style="max-width: 400px"
            type="submit"
          />
        </q-card-actions>
      </q-card>
    </q-form>

    <br />

    <q-separator />

    <div class="q-py-md" v-if="dataClick.length > 0">
      <q-table
        flat
        bordered
        :data="dataClick"
        :columns="columns"
        row-key="affiliate_id"
        selection="multiple"
        :selected.sync="selected"
        :pagination="{ rowsPerPage: 10 }"
      />

      <q-card flat class="bg-grey-3 q-mt-xl q-pt-md">
        <q-form @submit.prevent="validateClicks">
          <q-card-section class="row q-col-gutter-md q-pa-md">
            <div class="col-6">
              <q-select
                v-model="parameters.type"
                outlined
                bg-color="white"
                :options="[
                  { label: 'Quantidade', value: 'isQtd' },
                  { label: 'Porcentagem', value: 'isPct' }
                ]"
                label="Tipo de validação"
                :rules="[(val) => !!val || 'O campo tipo de validação é obrigatório.']"
              />
            </div>
            <div class="col-6">
              <q-input
                outlined
                bg-color="white"
                label="Digite o valor"
                v-model="parameters.value"
                type="text"
                :rules="[(val) => !!val || 'O campo tipo de valor é obrigatório.']"
              />
            </div>

            <div class="col-5">
              <q-select
                v-model="parameters.status_from"
                outlined
                bg-color="white"
                :options="[
                  { label: 'Aprovado', value: 1 },
                  { label: 'Reprovado', value: 0 },
                  { label: 'Pendente', value: 2 }
                ]"
                label="Alterar status de"
                :rules="[(val) => !!val || 'O campo de status é obrigatório.']"
              />
            </div>
            <div class="col-2 text-center">
              <q-icon name="fa fa-chevron-right" class="q-mt-sm" size="lg" />
            </div>
            <div class="col-5">
              <q-select
                v-model="parameters.status_to"
                outlined
                bg-color="white"
                :options="[
                  { label: 'Aprovado', value: 1 },
                  { label: 'Reprovado', value: 0 },
                  { label: 'Pendente', value: 2 }
                ]"
                label="Alterar status para"
                :rules="[(val) => !!val || 'O campo de status é obrigatório.']"
              />
            </div>

            <div class="col-5">
              <q-btn
                unelevated
                class="bg-primary"
                label="Validar Alteração"
                color="positive"
                no-caps
                style="max-width: 200px"
                type="submit"
              />
            </div>
          </q-card-section>
        </q-form>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  components: { SelectRequest, DateInput },
  name: 'CommissionAdjustments',
  data() {
    return {
      selected: [],
      parameters: {
        type: null,
        value: null,
        status_from: null,
        status_to: null
      },
      params: {
        campaignId: null,
        date: null
      },
      page: 1,
      columns: [
        {
          name: 'Id do Afiliado',
          label: 'Id do Afiliado',
          align: 'left',
          field: 'affiliate_id',
          sortable: true
        },
        {
          name: 'Id do Canal',
          label: 'Id do Canal',
          align: 'left',
          field: 'site_id',
          sortable: true
        },
        {
          name: 'Canal de Divulgação',
          label: 'Canal de Divulgação',
          align: 'left',
          field: 'site_name',
          sortable: true
        },
        {
          name: 'Cliques Pendentes',
          label: 'Cliques Pendentes',
          align: 'left',
          field: 'pending',
          sortable: true
        },
        {
          name: 'Cliques Aprovados',
          label: 'Cliques Aprovados',
          align: 'left',
          field: 'approved',
          sortable: true
        },
        {
          name: 'Cliques Recusados',
          label: 'Cliques Recusados',
          align: 'left',
          field: 'denied',
          sortable: true
        },
        {
          name: 'Cliques Totais',
          label: 'Cliques Totais',
          align: 'left',
          field: 'total',
          sortable: true
        }
      ],
      dataClick: []
    };
  },
  methods: {
    async validateClicks() {
      if (this.selected.length <= 0) {
        this.errorNotify('É necessário escolher pelo menos um site.');
        return;
      }

      let site_id = this.selected.map((item) => item.site_id),
        pre_start_date = this.params.date.split(' - ')[0].split('/'),
        pre_end_date = this.params.date.split(' - ')[1].split('/'),
        start_date = `${pre_start_date[2]}-${pre_start_date[1]}-${pre_start_date[0]}`,
        end_date = `${pre_end_date[2]}-${pre_end_date[1]}-${pre_end_date[0]}`;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.put('/redirect', {
          app: 'conversorphp',
          path: `/api/updateClicks/${this.params.campaignId}`,
          params: `{
              "data":{
                "site_id": [${site_id}],
                "start_date": "${start_date}",
                "end_date": "${end_date}",
                "type_validate": "${this.parameters.type.value}",
                "status": ${this.parameters.status_to.value},
                "status_from": ${this.parameters.status_from.value},
                "value_validate": "${this.parameters.value}"
              }
            }`
        });
        if (status === 200) {
          this.successNotify(data[0]);
          this.onFilterCliks();
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async onFilterCliks() {
      let start_date = this.params.date.split(' - ')[0],
        end_date = this.params.date.split(' - ')[1],
        queryString,
        queryStringUrl;

      queryString = `id_campanha=${this.params.campaignId}`;
      queryString += `&page=${this.page}`;

      // if (!perPage) queryString += "&perPage=10";
      queryString += `start_date=${start_date}`;
      queryString += `end_date=${end_date}`;

      queryStringUrl = encodeURI(queryString);

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=conversorphp&path=/api/get/clicks/validation?${queryStringUrl}`
        );
        if (status === 200) {
          console.log('data', data);
          this.dataClick = data.data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style></style>
